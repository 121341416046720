.contentMainBox{
    width: 88px;
    height: 100vh;
    min-height: 750px;
    position: fixed;
    top:0;
    right: 0;
    flex-direction: column;
    display: flex;
    background-color: white;
    color: black;
    cursor: pointer;
}

.iconBox{
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exploreString{
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    text-align: center;
    font-size: 36px;
}

.exploreBox{
    height: calc(100vh - 88px);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}