.contentMainBox{
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: row;
    background-color: black;
    color: white;
}

.iconBox{
    width: 88px;
    height: 88px;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

.mainContent{
    width: 100%;
    min-height: 750px;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.sideContent{
    height: 100vh;
    width: 88px;
    min-height: 750px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    background-color: black;
}

.navBarContent{
    height: 88px;
    z-index: 1000;
    width: calc(100% - 176px);
    position: fixed;
    top:0;
    left:88px;
    background-color: black;
    color: white;
    align-items: center;
    align-content: center;
}

.navBarLinks{
    display: flex;
    text-decoration-skip-ink: none;
    text-underline-position: form-font;
    flex-direction: row;
    gap: 36px;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.navBarLinks p {
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Amina;
    transition: font-family 0.2s ease;
}

.navBarLinks p:hover {
font-family: Amina-Bold;
}

/* Content section styles */

.contentPresenter{
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

.sectionContainer{
    width: 100%;
    max-width: 1744px;
    margin: 88px auto 0;
    padding-left: 88px;
    flex-direction: column;
}

.containerTitle{
    font-size: 32px;
    font-weight: 400;
    font-family: Amina;
    line-height: 50px;
    margin-bottom: 0;
    margin-top: 0;
}
.containerTitleSecondary{
    font-size: 12px;
    text-align: center;
    font-family: Everett;
    line-height: 24px;
    margin-top: 0;
}

.videoBox{
    height: calc(100vh - 88px);
    width: calc(100vw - 176px);
    margin-top: 88px;
    margin-left: 88px;
    padding-left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.videoPlayer{
    width: 100%;
    height: calc(100vh - 88px);
    object-fit: cover;
}

.amplioSimple{
    width: calc(100%);
    height: calc(100vh - 88px);
    padding-top: 88px;
}

.contentPresenter{
    justify-content: center;
    align-content: center;
}

.containerText{
    font-size: 20px;
    line-height: 43px;
    font-family: Everett;
    margin-top: 95px;
    font-style: normal;
    width: 625px;
}

.containerTextHighLighted{
    width: 625px;
    position: relative;
    font-size: 36px;
    line-height: 50px;
    text-transform: lowercase;
    font-family: Amina;
    color: white;
    text-align: left;
    display: inline-block;
}

.whiteHighlight{
    color:black;
    background-color: white;
}

.assemblyCellsFor {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 50px;
    font-family: Everett;
    font-weight: 400;
}
.industrialAutomationSolution {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: 27px;
}

.industrialAutomationSolutionContainer {
    font-size: 20px;
    line-height: 50px;
    color: #fff;
    text-align: left;
}

.arrowIconRight{
    cursor: pointer;
    margin-top: 420px;
}

.ourFlagshipProductContainer{
    margin: 0;
    font-size: 36px;
    line-height: 50px;
    font-weight: bold;
    font-family: Amina;
    margin-bottom: 0;
    color: black;
}

.ourFlagshipProduct{
    font-size: 32px;
    line-height: 50px;
    margin-left: 40px;
    margin-top: 10px;
    text-transform: none;
    font-family: Amina-Bold;
    text-align: justify;
    color: black;
}

.whiteContainer{
    margin-top: 100px;
    height: 260px;
    width: 625px;
    background-color: white;
}

.flagshipProductText{
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
    margin-left: 40px;
    margin-bottom: 23px;
    margin-right: 60px;
    font-weight: 400;
    font-style: bold;
    font-family: Amina;
    color: black;
}

.contactCard {
    position: absolute;
    top: 50%;
    left: 280px;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    border-radius: 5%;
    padding: 32px;
    z-index: 2;
    width: 100%;
    max-width: 320px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.contactInfo {
    flex-grow: 1;
    margin-bottom: 0;
    padding-bottom: 0;
}

.contactTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contactClose {
    position: absolute;
    top: 32px;
    right: 32px;
    font-size: 24px;
    cursor: pointer;
}

.contactRow {
    margin-bottom: 10px;
}

.contactLabel {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.contactText {
    font-size: 20px;
    line-height: 1.5;
}

.contactDescription {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 15px;
    padding-top: 0;
    margin-bottom: 15px;
}

.socialIcons {
    display: flex;
    gap: 16px;
}

.socialIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.maps {
    width: calc(100% - 88px);
    margin-left: 88px;
    height: calc(100vh - 88px);
    border: none;
    position: relative;
    z-index: 1;
}

.socialClickable{
    cursor: pointer;
}

.footer{
    position: relative;
    bottom: 0;
    display: inline-block;
    color: white;
    background-color: black;
}

.footerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 24px;
}

.footerItem {
    position: relative;
    padding: 16px 48px;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid white;
}

.footerTitle {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
}

.footerClose {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
}

.requirementsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    width: 100%;
}

.requirementsBox {
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 30px;
}

.requirementsBox h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.2;
    color: white;
}

.requirementsBox ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.requirementsBox li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 1.5;
}

.requirementsBox li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: white;
}

.requirementsFooter {
    text-align: center;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
}

.lightChain{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 40px;
    margin-left: 40px;
    align-content: center;
}

/* Grid rendszer */
.gridLeft {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.gridCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.gridRight {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

/* Container a fix szélességű tartalomnak */
.fixedWidthContainer {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

.mapContainer {
    position: relative;
    width: 100%;
    height: 100vh;
}

.mapImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}