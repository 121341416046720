.mobileContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.iconBox {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.cohorsText {
    margin-left: 28px;
    font-family: Everett;
}

.amplioText {
    margin-left: 28px;
    font-family: Everett;
}

.containerText{
    width: 100%;
    font-size: 20px;
    line-height: 43px;
    font-family: Everett;
    margin-top: 95px;
    font-style: normal;
}

.containerTextHighLighted{
    position: relative;
    width: 100%;
    font-size: 36px;
    line-height: 50px;
    font-family: Amina-Bold;
    color: white;
    text-align: left;
    display: inline-block;
}

.whiteHighlight{
    color:black;
    background-color: white;
}

.blackHighlight{
    background-color: black;
    color: white;
}

.assemblyCellsFor {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 50px;
    font-family: Everett;
    font-weight: 400;
}
.industrialAutomationSolution {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    padding-left: 27px;
}

.industrialAutomationSolutionContainer {
    font-size: 20px;
    line-height: 50px;
    color: #fff;
    text-align: left;
}

.arrowIconRight{
    cursor: pointer;
    margin-top: 420px;
}

.lightChain{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 65px;
    align-content: center;
}

.section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease;
}

.amplioSection {
    background-color: black;
    color: white;
}

.cohorsSection {
    background-color: white;
    color: black;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.logo {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
}

.menuButton {
    background: none;
    border: none;
    font-size: 24px;
    color: inherit;
    z-index: 101;
}

.menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: inherit;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 99;
}

.menu a {
    color: inherit;
    text-decoration: none;
    padding: 15px 0;
    font-size: 16px;
}

.videoContainer {
    flex: 1;
    width: 100%;
    height: calc(100vh - 112px);
    margin-top: 56px;
    overflow: hidden;
}

.videoPlayer {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer {
    height: 60px;
    border-top: 1px solid currentColor;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: inherit;
}

.footerContent {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ourFlagshipProductContainer{
    margin: 0;
    margin-left: 19px;
    font-size: 36px;
    line-height: 50px;
    font-weight: bold;
    font-family: Amina;
    margin-bottom: 0;
    color: black;
}

.ourFlagshipProduct{
    font-size: 32px;
    line-height: 50px;
    margin-top: 10px;
    text-transform: none;
    font-family: Amina-Bold;
    text-align: justify;
    color: black;
}

.whiteContainer{
    margin-top: 0;
    width: 100%;
    padding-bottom: 30px;
    background-color: white;
}

.flagshipProductText{
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
    margin-left: 19px;
    margin-bottom: 23px;
    margin-right: 60px;
    font-weight: 400;
    font-style: bold;
    font-family: Amina;
    color: black;
}

.advantageTitle{
    font-size: 32px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 42px;
    font-family: Amina-Bold;
}

.advantageText{
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    text-align: justify;
    font-family: Everett;
    text-align: justify;
}

.containerTextBold{
    font-family: Amina-Bold;
    font-size: 32px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
}
.requirementsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    width: 100%;
}

.requirementsBox {
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 30px;
}

.requirementsBox h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1.2;
    color: white;
}

.requirementsBox ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.requirementsBox li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 1.5;
}

.requirementsBox li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: white;
}

.requirementsFooter {
    text-align: center;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
}

.containerTitle{
    font-size: 32px;
    font-weight: 400;
    font-family: Amina;
    line-height: 50px;
    margin-bottom: 0;
    margin-top: 0;
}
.containerTitleSecondary{
    font-size: 12px;
    font-family: Everett;
    line-height: 24px;
    margin-top: 0;
}

.contactCard {
    background-color: white;
    color: black;
    padding: 32px;
    border-radius: 5%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 50px;
}

.contactInfo {
    flex-grow: 1;
    margin-bottom: 0;
    padding-bottom: 0;
}

.contactTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contactClose {
    position: absolute;
    top: 32px;
    right: 32px;
    font-size: 24px;
    cursor: pointer;
}

.contactRow {
    margin-bottom: 10px;
}

.contactLabel {
    font-size: 14px;
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.contactText {
    font-size: 20px;
    line-height: 1.5;
}

.contactDescription {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 15px;
    padding-top: 0;
    margin-bottom: 15px;
}

.socialIcons {
    display: flex;
    gap: 16px;
}

.socialIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.mapImg{
    width: 100%;
    margin-bottom: 56px;
}