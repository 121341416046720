.accordionContainer {
    width: 100%;
    max-width: 1200px;
    margin: 15px auto;
}

@media screen and (min-width: 768px) {
    .accordionContainer {
        margin: 50px auto 0;
    }
}

.accordionItem {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
}

.accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accordionHeader:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.headerContent {
    flex: 1;
}

.headerContent h3 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.headerContent p {
    margin: 5px 0 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

.toggleIcon {
    font-size: 60px;
    padding-left: 34px;
    color: white;
    width: 30px;
    font-family: Amina-Bold;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordionContent {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.02);
    color: white;
}

.accordionContent h4 {
    margin: 0 0 15px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
}

.accordionContent p {
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.7);
}