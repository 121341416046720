.contentMainBox{
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    width: 100%;
    background-color: white;
    color: black;
}

.iconBox{
    width: 100%;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContent{
    height: 100vh;
    width: 100%;
    min-height: 750px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.sideContent{
    height: 100vh;
    width: 88px;
    min-height: 750px;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    background-color: white;
}

.navBarContent{
    height: 88px;
    width: calc(100% - 176px);
    position: fixed;
    top:0;
    left:88px;
    background-color: white;
    align-items: center;
    align-content: center;
}

.navBarLinks{
    display: flex;
    text-decoration-skip-ink: none;
    text-underline-position: form-font;
    flex-direction: row;
    gap: 36px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 10px;
    justify-content: flex-end;
    font-family: Amina;
    align-items: "center";
    align-content: "center";
}

.navBarLinks p {
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Amina;
    transition: font-family 0.2s ease;
}

.navBarLinks p:hover {
font-family: Amina-Bold;
}

.videoBox{
    height: calc(100vh - 88px);
    width: calc(100vw - 176px);
    margin-top: 88px;
    padding-left: 0;
    overflow: hidden;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.videoPlayer{
    width: 100%;
    height: calc(100vh - 88px);
    object-fit: cover;
}

.contentContainer{
    width: 100%;

}

.cohorsSimple{
    width: calc(100%);
    height: calc(100vh - 88px);
    padding-top: 88px;
}



.contentPresenter{
    height: 100%;
    justify-content: center;
    align-content: center;
}

.containerText{
    font-size: 16px;
    line-height: 24px;
    margin-top: 42px;
    text-align: justify;
}

.containerTextTitle{
    font-family: Amina-Bold;
    line-height: 50px;
    font-size: 32px;
    text-align: left;
    text-transform: lowercase;
}

.containerTitle{
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
}

.containerTextHighLighted{
    position: relative;
    font-size: 32px;
    line-height: 50px;
    font-family: Amina-Bold;
    margin-bottom: 0;
    color: white;
    display: inline-block;
}

.blackHighlight{
    background-color: black;
    color: white;
}

.cohorsPros{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: start;
}

.cohorsProItem{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    margin-bottom: 10px;
}

.footer{
    position: relative;
    bottom: 0;
    display: inline-block;
    color: black;
    background-color: white;
}

.footerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.footerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 24px;
}

.footerItem {
    position: relative;
    background-color: white;
    padding: 16px 48px;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid  black;
}

.footerTitle {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    color: black;
}

.footerClose {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: black;
}

.advantageTitle{
    font-size: 32px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 50px;
    font-family: Amina-Bold;
}

.advantageText{
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    text-align: justify;
    font-family: Everett;
    text-align: justify;
}

/* Grid rendszer */
.gridLeft {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.gridCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.gridRight {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

/* Container a fix szélességű tartalomnak */
.fixedWidthContainer {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

